// bookshelf-app/src/components/bookshelf.tsx

// Import deps
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// Import components
import { LanguageList } from './language-list'

// Create Bookshelf component
export const Lingopal = () => {
  // Prepare states
  const [nativeLanguages, setNativeLanguages] = useState([])
  const [languages, setLanguages] = useState([])
  const [categories, setCategories] = useState([])
  const [phrasesCategories, setPhrasesCategories] = useState([])
  const [phrases, setPhrases] = useState([])
  const [phonetics, setPhonetics] = useState([])
  const [loading, setLoading] = useState(true)

  
  useEffect(() => {
    fetchNativeLanguages()
    fetchLanguages()
    fetchCategories()
    fetchPhrasesCategories()
    fetchPhrases()
    fetchPhonetics()
  }, [])

  const fetchNativeLanguages = async () => {
    axios
      .get('/lingopal/native_language')
      .then(response => {
        setNativeLanguages(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  const fetchLanguages = async () => {
    axios
      .get('/lingopal/language')
      .then(response => {
        setLanguages(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  const fetchCategories = async () => {
    axios
      .get('/lingopal/category')
      .then(response => {
        setCategories(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  const fetchPhonetics = async () => {
    axios
      .get('/lingopal/phonetics')
      .then(response => {
        setPhonetics(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  const fetchPhrasesCategories = async () => {
    axios
      .get('/lingopal/phrase_category')
      .then(response => {
        setPhrasesCategories(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  const fetchPhrases = async () => {
    axios
      .get('/lingopal/phrase')
      .then(response => {
        setPhrases(response.data)

        // Update loading state
        setLoading(false)
      })
      .catch(error => console.error(`There was an error retrieving the data: ${error}`))
  }

  return (
    <Container maxWidth="lg">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LanguageList 
                    nativeLanguages={nativeLanguages} 
                    languages={languages} 
                    categories={categories} 
                    phonetics={phonetics} 
                    phrases={phrases} 
                    phrasesCategories={phrasesCategories} 
                    loading={loading} 
                />
            </Grid>
        </Grid>
    </Container>
  )
}