import React from 'react'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItemText';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

export const PhraseItem = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    let phoneticText = "";

    if (typeof props.phoneticPhrase !== 'undefined') {
        phoneticText = props.phoneticPhrase.description;
    }

    return (   
        <ListItem>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={props.phrase.phrase} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>


            <Collapse in={open} timeout="auto" unmountOnExit>

                <Card>

                    <CardContent variant="outlined">
                        
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <h3>Translation</h3>
                                {props.translatedPhrase.phrase}
                            </Grid>
                            
                            {phoneticText !== "" &&
                                <Grid item xs={12}>
                                    <h3>Phonetic</h3>
                                    {phoneticText}
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <h3>Listen</h3>
                                <audio
                                    controls
                                    src={"/lingopal/audio?language_id=" + props.translatedPhrase.language_id + "&phrase_id=" + props.translatedPhrase.phrase_id}>
                                        Your browser does not support the
                                        <code>audio</code> element.
                                </audio>
                            </Grid>        
                        </Grid>
                    </CardContent>
                </Card>
            </Collapse>
        </ListItem>
    )
}
