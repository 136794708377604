// Import deps
import React from 'react'
import styled from 'styled-components'


// Import components
import { FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { PhraseItem } from './phrase-item';
import { useSearchParams } from "react-router-dom";



const Flag = styled.img`
  padding-right: 10px;
  vertical-align: middle;
  padding-bottom: 2px;
`;

const Debug = styled.span`
  display: none;
`;


function GetUrlData(searchParams) {
  let fromLangId = parseInt(searchParams.get("fromlang"));
  let toLangId = parseInt(searchParams.get("tolang"));
  let genderId = parseInt(searchParams.get("gender"));
  let categoryId = parseInt(searchParams.get("category"));
  
  if (isNaN(fromLangId)) {
    fromLangId = 61; // 61 - Svenska
  }
  if (isNaN(toLangId)) {
    toLangId = 67; // 67 - Vietnam
  }
  if (isNaN(genderId)) {
    genderId = 2; // 2 - Male | 1 - Female 
  }
  if (isNaN(categoryId)) {
    categoryId = 25; // 25 - Flörta
  }

  return { fromLangId, toLangId, genderId, categoryId};
}


export const LanguageList = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();

  const { fromLangId, toLangId, genderId, categoryId} = GetUrlData(searchParams);

  const [fromLanguage, setFromLanguage] = React.useState(fromLangId);
  const [toLanguage, setToLanguage] = React.useState(toLangId); 
  const [gender, setGender] = React.useState(genderId); 
  const [category, setCategory] = React.useState(categoryId);
  

  if (props.loading) {
    return <p>Language data is loading...</p>
  }

  const handleChangeFromLanguage = (event) => {
    const fromLangId = parseInt(event.target.value);

    setSearchParams((prev) => {
      prev.set("fromlang", fromLangId);
      return prev;
    });

    setFromLanguage(fromLangId);
  };
  const handleChangeToLanguage = (event) => {
    const toLangId = parseInt(event.target.value);

    setSearchParams((prev) => {
      prev.set("tolang", toLangId);
      return prev;
    });

    setToLanguage(toLangId);
  };
  const handleChangeCategory = (event) => {
    const categoryId = parseInt(event.target.value);

    setSearchParams((prev) => {
      prev.set("category", categoryId);
      return prev;
    });

    setCategory(categoryId);
  };
  const handleChangeGender = (event) => {
    const genderId = parseInt(event.target.value);

    setSearchParams((prev) => {
      prev.set("gender", genderId);
      return prev;
    });

    setGender(genderId);
  };

  const filterPhrases = (category_id, language_id) => {

    const phraseIds = [];
    props.phrasesCategories.map( (phraseCategory) => {

      if (phraseCategory.category_id === category_id) {
        phraseIds.push(phraseCategory.phrase_id)
      }

      return true;
    });

    const phrases = props.phrases.filter( (phrase) => { return phraseIds.includes(phrase.phrase_id) && phrase.language_id === language_id });

    return phrases;
  };

  const translatedPhrases = filterPhrases(category, toLanguage);


  const getTranslatedPhrase = (phrase, translatedPhrases) => {

    return translatedPhrases.find(translatedPhrase => translatedPhrase.phrase_id === phrase.phrase_id)

  };

  const getPhoneticPhrase = (phrase) => {

    const phoneticPhrase = props.phonetics.find(phonetics => { 
        return phonetics.phrase_id === phrase.phrase_id && phonetics.language_id === toLanguage && phonetics.alphabet_id === 1;
      }
    )
    
    return phoneticPhrase;
  };  

  return (
    <div>

      <FormControl variant="standard" sx={{ m: 1, width: 1 }}>
        <InputLabel id="from-language">From language</InputLabel>
        <Select
          labelId="from-language-standard-label"
          id="from-language-standard-label"
          value={fromLanguage}
          onChange={handleChangeFromLanguage}
          label="From language"
        >
          {
            (
              props.nativeLanguages.map((language, idx) => (
              <MenuItem key={idx} value={language.language_id}>
                <Flag alt="" src={'/assets/images/' + language.language_code + '.png'} /> 
                {language.language_name} <Debug>({language.language_id})</Debug>
              </MenuItem>
              ))
            )
          }

        </Select>
      </FormControl>

      {fromLanguage !== '' && 
      
          <FormControl variant="standard" sx={{ m: 1, width: 1 }}>
            <InputLabel id="from-language">My gender</InputLabel>
            <Select
              labelId="gender-standard-label"
              id="gender-standard-label"
              value={gender}
              onChange={handleChangeGender}
              label="Gender"
            >

              <MenuItem key='2' value='2'>
                Male
              </MenuItem>
              <MenuItem key='1' value='1'>
                Female
              </MenuItem>
            </Select>
          </FormControl>
    
      } 

      {fromLanguage !== '' && 
      
          <FormControl variant="standard" sx={{ m: 1, width: 1 }}>
            <InputLabel id="from-language">To language</InputLabel>
            <Select
              labelId="from-language-standard-label"
              id="from-language-standard-label"
              value={toLanguage}
              onChange={handleChangeToLanguage}
              label="To language"
            >

              { 
                (
                  props.languages.filter(lang_entry => (lang_entry.to_language_id === fromLanguage && (lang_entry.gender === 0 || lang_entry.gender === gender))).map((language, idx) => (
                    <MenuItem key={idx} value={language.language_id}>
                      <Flag alt="" src={'/assets/images/' + language.language_code + '.png'} />
                      {language.language_name} <Debug>({language.language_id})</Debug>
                    </MenuItem>
                  )
                  )
                )
              }

            </Select>
          </FormControl>
    
      } 

      {toLanguage !== '' && 
      
        <FormControl variant="standard" sx={{ m: 1, width: 1 }}>
          <InputLabel id="category">Category</InputLabel>
          <Select
            labelId="category-standard-label"
            id="category-standard-label"
            value={category}
            onChange={handleChangeCategory}
            label="Category"
          >

            {
              (
                props.categories.filter(category_entry => category_entry.language_id === fromLanguage).map((category, idx) => (
                  <MenuItem key={idx} value={category.category_id}>
                    {category.category_name} <Debug>({category.category_id})</Debug>
                  </MenuItem>
                )
                )
              )
            }

          </Select>
        </FormControl>
    
      } 

      {category !== '' && 

        <List
          sx={{ width: 1 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Phrases
            </ListSubheader>
          }
        >
        {
          filterPhrases(category, fromLanguage).map((phrase, idx) => (
          <PhraseItem key={idx} 
            phrase={phrase} 
            translatedPhrase={getTranslatedPhrase(phrase, translatedPhrases)} 
            phoneticPhrase={getPhoneticPhrase(phrase)} 
            
          />
        ))
        }
      </List>
    }
 
    </div>
  )
}