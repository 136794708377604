import React from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Lingopal } from './components/lingopal'
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';


function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Lingopal />
    </ThemeProvider>
  );
}

export default App;
